<template>
  <div data-app>
    <v-card>
      <v-card-title>
        Sales Orders
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-row>
          <SalesOrderModalForm
            :modalData="modalData"
            :editedItemId="editedItemId"
            :permissions="permissions"
            :userCollection="userCollection"
            :salesPointCollection="salesPointCollection"
            :sourceTypeCollection="sourceTypeCollection"
            :priceListCollection="priceListCollection"
            :itemCollection="itemCollection"
            :salesOrderStatuses="salesOrderStatuses"
            :quantityUnitCollection="quantityUnitCollection"
            @closeModalForm="handleCloseModalForm"
            @saveModalForm="handleSaveModalForm"
            @handleDeleteSalesOrder="handleDeleteSalesOrder"
            @handleFetchContactList="handleFetchContactList"
            @handleNew="handleNew"
          ></SalesOrderModalForm>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>

          <v-radio-group v-model="selectedList" row>
            <template v-slot:label>
              <div>Select sales order list</div>
            </template>
            <v-radio label="Active" value="active"></v-radio>
            <v-radio label="Inactive" value="inactive"></v-radio>
            <v-radio label="Lost" value="lost"></v-radio>
          </v-radio-group>
        </v-row>
        <v-row>
          <!-- :expanded.sync="expanded" -->
          <!-- show-expand -->
          <v-data-table
            :headers="headers"
            :items="salesOrderCollection"
            :search="search"
            item-key="so_number"
            @click:row="handleEditItem"
            style="cursor: pointer"
            :sort-by="['so_number']"
            sort-desc="true"
            :loading="loadingTable"
          >
            <template #header="{ props: { headers } }">
              <thead class="v-data-table-header">
                <tr>
                  <th></th>
                  <th></th>
                  <th>
                    <v-select
                      v-model="salesPersonNameFilter"
                      :items="[
                        { salesPersonName: 'Select' },
                        ...salesPersonCollection,
                      ]"
                      item-text="salesPersonName"
                      item-value="salesPersonName"
                    ></v-select>
                  </th>
                  <th></th>
                  <th style="max-width: 140px">
                    <v-select
                      v-model="currencyFilter"
                      :items="[{ currency: 'Select' }, ...currencyCollection]"
                      item-text="currency"
                      item-value="currency"
                    ></v-select>
                  </th>
                  <th></th>
                  <th></th>
                  <th style="max-width: 150px">
                    <v-select
                      v-model="statusFilter"
                      :items="[{ status: 'Select' }, ...statusCollection]"
                      item-text="status"
                      item-value="status"
                    ></v-select>
                  </th>
                  <th></th>
                  <th></th>
                  <!-- <th></th>
                  <th></th>
                  <th></th>
                  <th></th> -->
                </tr>
              </thead>
            </template>
            <template v-slot:item.total_net="{ item }">
              {{ $helpers.getNicePrice(item.total_net, item.currency) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <router-link
                :to="`/sales/salesorders/${item.id}`"
                v-if="permissionCan('view')"
              >
                <v-icon small class="mr-2"> mdi-pencil </v-icon>
              </router-link>
              <v-icon
                v-if="permissionCan('delete')"
                small
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
          <!-- <template
            v-slot:expanded-item="{ headers, item }"
            style="margin: 15px"
          >
            <td></td>

            <td colspan="2">
              {{ item.company_name }}<br />
              {{ item.contact_name }}
            </td>
            <td class="pt-3 pb-3">
              <b>Approved By:</b> {{ item.approved_by }}<br />
              <b>Approved at:</b> {{ item.approved_at }}
              <hr />
              <b>Sent by:</b> {{ item.sent_by }}<br />
              <b>Sent at:</b> {{ item.sent_at }}
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td v-html="item.itemsAllNames" class="pt-3 pb-3"></td>
            <td></td>
          </template> -->
        </v-row>
        <v-overlay verlay :value="loader">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ApiService from "@/core/services/api.service";

import SalesOrderModalForm, { initialFormData } from "./SalesOrdersModalForm";

export const ENDPOINT = "sales/salesOrder/";

export default {
  name: "SalesOrders",
  components: { SalesOrderModalForm },
  data() {
    return {
      loader: false,
      expanded: [],
      singleExpand: false,
      showAll: false,
      search: "",
      collection: [],
      permissions: [],
      salesPersonNameFilter: "Select",
      statusFilter: "Select",
      currencyFilter: "Select",
      selectedList: "active",
      // { text: "", value: "data-table-expand" },
      headers: [
        {
          text: "SO ID",
          value: "so_number",
        },
        {
          text: "Customer",
          value: "customerName",
        },
        {
          text: "Sales person",
          value: "salesPersonName",
          filter: (value) => {
            if (this.salesPersonNameFilter == "Select") return true;
            return value == this.salesPersonNameFilter;
          },
        },
        {
          text: "Value",
          value: "total_net",
        },
        {
          text: "Currency",
          value: "currency",
          filter: (value) => {
            if (this.currencyFilter == "Select") return true;
            return value == this.currencyFilter;
          },
        },
        {
          text: "Expiration",
          value: "expiration_date",
        },
        {
          text: "Exp. delivery",
          value: "delivery_date",
        },

        {
          text: "State",
          value: "status",
          filter: (value) => {
            if (this.statusFilter == "Select") return true;
            return value == this.statusFilter;
          },
        },
        {
          text: "Items",
          value: "itemsShortNames",
        },
      ],

      modalData: {
        dialog: false,
        loading: false,
        // editedItem: Object.assign({}, initialFormData),
      },
      // editedItem: Object.assign({}, initialFormData),
      editedItemId: null,
      dialogDelete: false,
      loadingTable: true,
      salesOrderStatuses: [],
    };
  },

  computed: {
    ...mapGetters([
      "salesOrderCollection",
      "userCollection",
      "contactListCollection",
      "salesPointCollection",
      // "currentUser",
      "sourceTypeCollection",
      "priceListCollection",
      "itemCollection",
      "quantityUnitCollection",
    ]),
    salesPersonCollection() {
      return this.salesOrderCollection
        .filter((i) => i.salesPersonName)
        .map((item) => {
          return item.salesPersonName;
        });
    },
    statusCollection() {
      return this.salesOrderCollection
        .filter((i) => i.status)
        .map((item) => {
          return item.status;
        });
    },
    currencyCollection() {
      return this.salesOrderCollection
        .filter((i) => i.currency)
        .map((item) => {
          return item.currency;
        });
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.$nextTick(() => {
            this.editedItemId = id;
            this.modalData.dialog = true;
          });
        }
      },
      deep: true,
      immediate: true,
    },
    quantityUnitCollection(val) {
      this.filterCollection(val);
    },

    selectedList(val) {
      this.$store.dispatch("fetchSalesOrder", "sales/salesOrder/" + val);
    },
  },

  methods: {
    ...mapActions([
      "fetchSalesOrder",
      "fetchUser",
      "fetchContactList",
      "fetchSalesPoint",
      "fetchSourceType",
      "fetchPriceList",
      "fetchItem",
      "fetchQuantityUnit",
    ]),

    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    handleDeleteSalesOrder(item) {
      if (this.permissionCan("view")) {
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
      }
    },

    deleteItemConfirm() {
      ApiService.delete(ENDPOINT + this.editedItem.id)
        .then(() => {
          this.$store.dispatch(
            "fetchSalesOrder",
            "sales/salesOrder/" + this.selectedList
          );
          this.modalData.dialog = false;
          this.editedItem = Object.assign({}, initialFormData);
          this.handleCloseModalForm();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
      this.closeDelete();
    },

    handleCloseModalForm() {
      // this.loader = true;
      // this.loadingTable = true;
      // this.modalData.dialog = false;
      // this.fetchContactList().then(() => {
      //   this.editedItemId = null;
      //   this.$store
      //     .dispatch("fetchSalesOrder", "sales/salesOrder/" + this.selectedList)
      //     .then(() => {
      //       this.loader = false;
      //       this.loadingTable = false;
      //     });
      // });
      // if (this.$route.name !== "salesorders")
      //   this.$router.push({ name: "salesorders" });

      this.loader = true;
      this.loadingTable = true;
      this.modalData.dialog = false;
      this.$store
        .dispatch("fetchSalesOrder", "sales/salesOrder/" + this.selectedList)
        .then(() => {
          this.loader = false;
          this.loadingTable = false;
        });
      this.editedItemId = null;

      this.fetchContactList();

      if (this.$route.name !== "salesorders")
        this.$router.push({ name: "salesorders" });
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    // handleSaveModalForm(item) {
    handleSaveModalForm() {
      // this.fetchContactList().then(() => {
      //   this.$store.dispatch(
      //     "fetchSalesOrder",
      //     "sales/salesOrder/" + this.selectedList
      //   );
      // });

      this.$store.dispatch(
        "fetchSalesOrder",
        "sales/salesOrder/" + this.selectedList
      );
      this.fetchContactList();
    },

    handleNew() {
      this.editedItemId = null;
      this.editedItem = Object.assign({}, initialFormData);
    },

    filterCollection(coll) {
      if (!this.showAll) {
        this.collection = coll;
      } else {
        this.collection = coll.filter((item) => {
          return item.status == 1;
        });
      }
    },

    handleEditItem(item) {
      if (this.$route.name !== "editsalesorders") {
        this.$router.push({ name: "editsalesorders", params: { id: item.id } });
      }
    },

    permissionCan(action) {
      return this.permissions.find(
        (x) => x.name == "sales.salesOrder." + action
      );
    },

    setPermissions() {
      if (localStorage.permissions) {
        this.permissions = JSON.parse(localStorage.permissions).filter(
          (item) => item.name.indexOf("salesOrder") > -1
        );
      }
    },

    handleFetchContactList() {
      // alert("handleFetchContactList");
      // this.fetchContactList();
    },
  },

  mounted() {
    this.fetchItem();
    if (!this.$route.params.id) {
      this.fetchContactList();
    }
    this.$store
      .dispatch("fetchSalesOrder", "sales/salesOrder/" + this.selectedList)
      .then(() => {
        this.loadingTable = false;

        this.salesOrderStatuses = window.localStorage.getItem(
          "salesOrderStatuses"
        )
          ? JSON.parse(window.localStorage.getItem("salesOrderStatuses"))
          : [];
      });

    this.fetchUser();
    this.fetchSalesPoint();
    this.fetchSourceType();
    this.fetchPriceList();
    this.fetchQuantityUnit();
    this.setPermissions();
  },
};
</script>
